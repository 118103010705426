import { Controller } from "stimulus"
import Coloris from "@melloware/coloris";

export default class extends Controller {
  connect() {
    Coloris.init();
    Coloris({
      el: this.element,
      themeMode: "auto",
      clearButton: true
    });
  }
}