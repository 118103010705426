import { Controller } from "stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = [
    "tab",
  ];

  onChangeTab(event) {
    const selectedTab = document.querySelector('.tab.selected');
    const selectedTabPane = document.querySelector(`#${selectedTab.id}_pane`);
    const targetTabPane = document.querySelector(`#${event.target.id}_pane`);

    if (event.target.id !== selectedTab.id) {
      selectedTab.classList.toggle('selected');
      selectedTabPane.classList.toggle('selected');

      event.target.classList.toggle('selected');
      targetTabPane.classList.toggle('selected');
    }
  }
}