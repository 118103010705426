import { Controller } from "stimulus"

// Connects to data-controller="constom-branding"
export default class extends Controller {
  static targets = [
    'tab',
    'tabPane',
    'primaryColor',
    'secondaryColor',
  ];

  static values = {
    initial: Object, // Inital customisation state from view
  };

  switchTab(event) {
    this.tabTargets.forEach((tab, i) => {
      if (event.target.id === tab.id) {
        tab.classList.add('selected');
        this.tabPaneTargets[i].classList.add('selected');
      } else {
        tab.classList.remove('selected');
        this.tabPaneTargets[i].classList.remove('selected');
      }
    });
  }

  preview(event) {
    const previewErr = document.querySelector('#logo_err');
    const preview = document.querySelector("#logo_preview");
    const { files } = event.target;

    preview.onload = function () {
      const msg = [];

      if (this.naturalWidth > 280) {
        msg[0] = '<p class="text-sm text-red-500">Image width is greater than 280 pixels<p>';
      }

      if (this.naturalHeight > 60) {
        msg[1] = '<p class="text-sm text-red-500">Image height is greater than 60 pixels</p>';
      }

      if (msg.length) {
        event.target.value = '';
        preview.src = '';
      }
      previewErr.innerHTML = msg.join('');
    };

    if (files && files[0] && preview) {
      const reader = new FileReader();
      reader.onload = (e) => {
        preview.src = e.currentTarget.result;
      }

      reader.readAsDataURL(files[0]);
    }
  }

  primaryColor(event) {
    this.primaryColorTarget.innerText = event.currentTarget.value;
  }

  secondaryColor(event) {
    this.secondaryColorTarget.innerText = event.currentTarget.value;
  }
}