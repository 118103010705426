// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['add', 'remove']

  toggle(e) {
    if (e.currentTarget.checked) {
      this.addTargets.forEach(el => el.click())
    } else {
      this.removeTargets.forEach(el => el.click())
    }
  }
}
