/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

if (!window.__appClientLoaded) {
  window.__appClientLoaded = true;

  require('@hotwired/turbo');
  Turbo.session.drive = false
}

import * as Turbo from '@hotwired/turbo'
import TurboMorph from 'turbo-morph'
TurboMorph.initialize(Turbo.StreamActions)

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
const application = Application.start()
application.load(definitionsFromContext(require.context("../controllers", true, /_controller\.js$/)))
application.load(definitionsFromContext(require.context("../controllers/shared", true, /_controller\.js$/)))
application.load(definitionsFromContext(require.context("../controllers/dashboard", true, /_controller\.js$/)))

import tippy, { hideAll } from "tippy.js"
import LazyLoad from "vanilla-lazyload"

$(document).ajaxComplete(function () {
  hideAll()
  tippy("[data-tippy-content]", {
    allowHTML: true,
    placement: "top",
    arrow: true,
  })

  if (window.lazyLoadInstance) {
    window.lazyLoadInstance.update()
  }
})

$(document).on("turbo:load", function () {
  window.lazyLoadInstance = new LazyLoad({})

  tippy("[data-tippy-content]", {
    allowHTML: true,
    placement: "top",
    arrow: true,
  })
})

window.tippy = tippy
