import { Controller } from "stimulus"

// Connects to data-controller="constom-branding"
export default class extends Controller {
  static targets = [
    'mobile',
    'header',
  ];

  static values = {
    init: Object, // Inital customisation state from view
  };

  connect() {
    this.headerTarget.style.backgroundColor = this.initValue.color_primary
    this.mobileTarget.style.backgroundColor = this.initValue.color_secondary;
  }

  backgroundColor(event) {
    this.mobileTarget.style.backgroundColor = event.currentTarget.value;
  }

  headerColor(event) {
    this.headerTarget.style.backgroundColor = event.currentTarget.value;
  }
}