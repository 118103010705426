import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['title', 'video']

  initialize() {
    if (!this.hasVideoTarget) return

    if (this.hasTitleTarget) {
      this.videoTargets.forEach((item) => {
        if ($(item).data('affected')) {
          $(item).find('.vjs-control-bar').addClass('has-title')
        }
      })
    }
  }
}
