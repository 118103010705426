import { Controller } from "stimulus"

// Connects to data-controller="customisation"
export default class extends Controller {
  static targets = [
    "questions",      // Targets custom questions checkbox field
    "maxQuestions",   // Targets custom question max input field
    "branding"        // Targets custom branding checkbox field
  ];

  onCustomQuestionsChange() {
    this.maxQuestionsTarget.disabled = !this.questionsTarget.checked;
  }
}