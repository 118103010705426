import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  initialize() {
    $('.partner_site_themes')
      .find('input:checkbox')
      .each((idx, item) => {
        let id = $(item).val()
        let checked = $(item).prop('checked')
        $(this.element)
          .find("input:checkbox[value='" + id + "']")
          .prop('checked', checked)
      })
  }

  toggleAll(event) {
    event.preventDefault()
    const selectType = $(event.currentTarget).data('select-type')
    const checked = selectType == 'select'
    const themeClass = $(event.currentTarget).data('theme-class')
    let realCheckboxes = this.checkboxTargets

    if (typeof themeClass !== 'undefined') {
      realCheckboxes = realCheckboxes.filter(
        (item) => $(item).data('theme-class') == themeClass
      )
    }

    realCheckboxes.forEach((item) => {
      $(item).prop('checked', checked)

      // check actual checkboxes
      let id = $(item).val()
      $('.partner_site_themes')
        .find("input:checkbox[value='" + id + "']")
        .prop('checked', checked)
    })
  }

  selectCheckbox(event) {
    let checked = $(event.currentTarget).prop('checked')
    let id = $(event.currentTarget).val()
    $('.partner_site_themes')
      .find("input:checkbox[value='" + id + "']")
      .prop('checked', checked)
  }
}
