// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['edit']

  connect() {
    this.originalUrl = this.editTarget.href

    var self = this
    $(this.element)
      .find('a[data-toggle="tab"]')
      .on('shown', function (e) {
        var target = $(e.target).attr('href') // activated tab
        self.editTarget.href = self.originalUrl + target
      })
  }
}
