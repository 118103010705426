import { Controller } from "stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = ["preview", "correctAnswerPosition"]

  connect() {
    this.preview = debounce(this.preview.bind(this), 1000)
  }

  preview() {
    this.previewTarget.click()
  }

  toggleCorrectAnswers(event) {
    this.correctAnswerPositionTarget.classList.toggle("hidden")
  }
}