// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  toggle(e) {
    if (e.currentTarget.checked) {
      this.formTarget.classList.remove("hidden")
      this.element.querySelectorAll(".add_fields").forEach(el => el.click())
    } else {
      this.formTarget.classList.add("hidden")
      this.element.querySelectorAll(".remove_fields").forEach(el => el.click())
    }
  }
}
